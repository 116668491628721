import { History } from "history";
import {
	MutableRefObject,
	forwardRef,
	useEffect,
	useMemo,
	useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { MicrofrontendCommunication } from "utils/microfrontend-communication";

type OneAvantPageProps = {
	internalPath: string;
	strictPath?: string;
	disabledPathSync?: boolean;
	// showSidePanel?: boolean;
	hideSidePanel?: boolean;
	/**
	 * @description whenever the path has left the internal path
	 */
	onPathLeave?: (path: string, history: History) => void;
	location?: {
		pathname: string;
	};
	match?: {
		url: string;
	};
};

const REACT_APP_ONE_AVANT_BASE_URL =
	process.env.REACT_APP_ONE_AVANT_BASE_URL ||
	window.REACT_APP_ONE_AVANT_BASE_URL ||
	"";

const OneAvantPage = forwardRef<HTMLIFrameElement | null, OneAvantPageProps>(
	function _OneAvantPage(
		props: OneAvantPageProps,
		_ref: MutableRefObject<HTMLIFrameElement | null>
	) {
		const internalRef = useRef<HTMLIFrameElement | null>(null);
		const _history = useHistory();

		const currentPathname = location?.pathname || "";

		const childPrefix = props.internalPath;
		const strictPath = props.strictPath || "";
		const parentPrefix = props.match?.url || "";

		const ref = useMemo(() => {
			return _ref || internalRef;
		}, [_ref, internalRef]);

		useEffect(() => {
			const returnToParentListener = MicrofrontendCommunication.listenTo({
				event: "RETURN_TO_PARENT",
				source: "all",
				listener: () => {
					_history.push("/");
				},
			});

			const showReturnToParentBeacon = MicrofrontendCommunication.beacon({
				event: "SHOW_RETURN_TO_PARENT",
				target: "all",
				data: {
					label: "Return to FFOps",
				},
			});

			if (props.disabledPathSync) return;

			const prefixesBeacon = MicrofrontendCommunication.beacon({
				event: "HERE_ARE_THE_PREFIXES",
				target: "other",
				data: {
					parentPrefix,
					childPrefix,
				},
			});

			const urlChangeListener = MicrofrontendCommunication.listenTo({
				event: "I_HAVE_CHANGED_MY_URL",
				source: "all",
				listener: (data: { yourNewUrl: string }) => {
					console.log(data);
					if (!data.yourNewUrl.includes(strictPath)) {
						console.log("got out of ", strictPath, data.yourNewUrl);
						props.onPathLeave?.(data.yourNewUrl, _history);
						return;
					}

					const newUrl = (
						"/" +
						parentPrefix +
						"/" +
						data.yourNewUrl.replace(childPrefix, "")
					).replace(/\/+/g, "/");

					if (newUrl !== _history.location.pathname) _history.replace(newUrl);
				},
			});

			return () => {
				showReturnToParentBeacon.close();
				returnToParentListener.close();
				urlChangeListener.close();
				prefixesBeacon.close();
			};
		}, [parentPrefix, childPrefix, props.disabledPathSync]);

		useEffect(() => {
			const hideSidePanelBeacon = MicrofrontendCommunication.beacon({
				event: "HIDE_YOUR_SIDEBAR",
				target: "all",
				data: {
					hide: props.hideSidePanel,
				},
				key: Math.random().toString(),
			});

			return () => {
				hideSidePanelBeacon.close();
			};
		}, [props.hideSidePanel]);

		const src = useMemo(() => {
			return (
				REACT_APP_ONE_AVANT_BASE_URL +
				(childPrefix + currentPathname.replace(parentPrefix, "")).replace(
					/\/+/g,
					"/"
				)
			);
		}, [childPrefix, parentPrefix]);

		if (!REACT_APP_ONE_AVANT_BASE_URL) {
			return <></>;
		}

		return (
			<iframe
				ref={ref}
				frameBorder="0"
				src={src}
				style={{
					width: "100%",
					height: "100dvh",
					display: "block",
				}}
				allow="camera; microphone; fullscreen; "
			></iframe>
		);
	} as any
);

export default OneAvantPage;
